import { useCurrentUser } from '../../../../auth/hooks/useAuth';

import { TeamDashboardPage } from '../../../teams/pages/TeamDashboardPage';
import { SingleUserDashboardPage } from '../../../users/pages/SingleUserDashboardPage';

function DashboardPage() {
  const currentUser = useCurrentUser();

  return currentUser?.client ? (
    <SingleUserDashboardPage />
  ) : (
    <TeamDashboardPage />
  );
}

export default DashboardPage;
