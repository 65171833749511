import React, { ReactNode } from 'react';
import compact from 'lodash/compact';

import { ClassName } from '../../../../types';
import { UserNanoID } from '../../usersTypes';

import {
  FETCH_SHOW_USER_QUERY,
  FetchShowUserQueryResponse
} from '../../queries/fetchShowUser.query';

import { useUser } from '../../hooks/useUser';
import { useTranslate } from '../../../../common/hooks/useTranslate';

import { SingleUserProfileSecondaryMenu } from '../../components/menus/SingleUserProfileSecondaryMenu';
import { ShowItemLayout } from '../../../common/layouts/ShowItemLayout';

import { AlertMessage } from '../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../helpers/LoadingSkeleton';

import { profilesKeys } from '../../../../locales/keys';
import { UsersPermissions, UserTabs } from '../../usersConstants';
import { UserCache } from '../../UserCache';

interface SingleUserShowLayoutProps {
  action: UsersPermissions;
  active?: UserTabs;
  userNanoId: UserNanoID;
  mainAddClassName?: ClassName;
  wrapperClassName?: ClassName;
  children: ReactNode;
  header?: ReactNode;
  headerWrapClassName?: ClassName;
}

function titleI18nSuffixFromTab(active: UserTabs) {
  switch (active) {
    case UserTabs.PROFILE:
      return profilesKeys.singular;
  }
  switch (active) {
    case UserTabs.FILES:
      return profilesKeys.files;
  }
  switch (active) {
    case UserTabs.RECORDS:
      return profilesKeys.records;
  }
  switch (active) {
    case UserTabs.REQUIREMENTS:
      return profilesKeys.requirements;
  }
}

function SingleUserShowLayout({
  children,
  userNanoId,
  active,
  action,
  headerWrapClassName,
  wrapperClassName,
  header,
  mainAddClassName
}: SingleUserShowLayoutProps) {
  const { user, userFetched, userError, userErrorData } =
    useUser<FetchShowUserQueryResponse>({
      cacheKey: UserCache.showCacheKey(),
      uuid: userNanoId,
      query: FETCH_SHOW_USER_QUERY
    });

  const titleI18nSuffix = titleI18nSuffixFromTab(active);

  const { t } = useTranslate();

  return (
    <ShowItemLayout
      withoutSecondaryMenu={!active}
      customSecondaryMenu={
        <SingleUserProfileSecondaryMenu
          userNanoId={userNanoId}
          user={user}
          userFetched={userFetched}
          active={active}
        />
      }
      mainAddClassName={mainAddClassName}
      action={action}
      error={userErrorData}
      header={header}
      headerWrapClassName={headerWrapClassName}
      title={
        user
          ? compact<string>([
              user.fullName,
              titleI18nSuffix ? t(titleI18nSuffix) : null
            ]).join(' - ')
          : `${userNanoId}`
      }
    >
      <div className={wrapperClassName}>
        <AlertMessage addClassName="m-4" message={userError} />
        <LoadingSkeleton
          addClass="absolute inset-0"
          loaded={user && userFetched}
        >
          {children}
        </LoadingSkeleton>
      </div>
    </ShowItemLayout>
  );
}
export default SingleUserShowLayout;
