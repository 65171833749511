import { gql } from 'graphql-request';

import {
  UserEmail,
  UserFullName,
  UserID,
  UserImageFile,
  UserUUID,
  UserNanoID,
  UserCompany,
  UserLocale,
  UserPhone,
  UserNameEn,
  UserSurnameEn,
  UserCreatedAt,
  UserUpdatedAt,
  UserLocation,
  UserDateOfBirth,
  UserTimeZone,
  UserNDA,
  UserCity,
  UserFavorite,
  UserBalanceAmount,
  UserDebt,
  UserRating,
  UserTotalAmount,
  UserTurnAroundTimeMonthly,
  UserClient,
  UserClientBalance,
  UserWorkerBalance,
  UserRatio,
  UserClientStatus,
  UserCategory,
  UserCompanySize,
  UserNoGo,
  UserSite,
  UserManualClientStatus,
  UserIntegration,
  UserSubCategories,
  UserSystemAccount,
  UserBusinessEmail,
  UserPosition,
  UserProficiencyLevel,
  UserRealDeclineReasons,
  UserReferer,
  UserSource,
  UserSpecializations,
  UserQualifications,
  UserWorkExperience,
  UserIsMentor,
  UserAdditionalInformation,
  UserName,
  UserNameUk,
  UserSurnameUk,
  UserSurname,
  UserProvidedFullName,
  UserProvidedFullNameEn,
  UserProvidedFullNameUk,
  UserRateEducationPoint,
  UserGroups,
  UserWildlyImportantGoalsCount,
  UserMentor,
  UserProjectManager,
  UserClientDebt,
  UserDocumentPages,
  UserWorkerRateMedian,
  UserWorkerRateAverage,
  UserClientRateAverage,
  UserClientRateMedian,
  UserCellId,
  UserCellName,
  UserFinanceRoleName,
  UserRatingCorrectedManually,
  UserHoldRate,
  UserDebtLimit,
  UserRoleId,
  UserCdn,
  UserAddress,
  UserLinkedIn,
  UserFacebook,
  UserSocialNetworks,
  UserUrlId,
  UserBlocked,
  UserTestProjectID,
  UserTestProjectUUID,
  UserEstimationAmount,
  UserTestProjectCreatedAt,
  UserLeadCreationDate,
  UserEstimationDate,
  UserTestProjectImplementationDate,
  UserTestProjectNanoID,
  UserTestTaskId,
  UserTheeDServicesId,
  UserTestProjectTaskStartDate,
  UserTestTaskNanoId,
  UserTestProjectMembersID,
  UserTestProjectMembersFullName,
  UserTestProjectMembersFinanceRoleName,
  UserBonusAccount,
  UserCurrentTeamID,
  UserCurrentTeamName,
  UserCurrentTeamNanoID,
  UserCurrentTeamType,
  UserAirTableApiKey,
  UserAirTableBaseId,
  UserAirTableEmail,
  UserAirTablePassword,
  UserAirTableTableName
} from '../usersTypes';
import { TeamName, TeamNanoID, TeamUUID } from '../../teams/teamsTypes';

export type FetchShowUserQueryResponseCell = {
  id: UserCellId;
  name?: UserCellName;
};
export type FetchShowUserQueryResponseCells = FetchShowUserQueryResponseCell[];
export interface FetchShowUserQueryResponse {
  address: UserAddress;
  additionalEmail1: UserEmail;
  additionalEmail2: UserEmail;
  additionalInformation: UserAdditionalInformation;
  airTableApiKey: UserAirTableApiKey;
  airTableBaseId: UserAirTableBaseId;
  airTableEmail: UserAirTableEmail;
  airTablePassword: UserAirTablePassword;
  airTableTableName: UserAirTableTableName;
  balanceAmount: UserBalanceAmount;
  bonusAccount: UserBonusAccount;
  blocked: UserBlocked;
  businessEmail: UserBusinessEmail;
  category: UserCategory;
  cells: FetchShowUserQueryResponseCells;
  city: UserCity;
  client: UserClient;
  clientBalance: UserClientBalance;
  clientManager: {
    id: UserID;
  };
  clientStatus: UserClientStatus;
  clientRateAverage: UserClientRateAverage;
  clientRateMedian: UserClientRateMedian;
  company: UserCompany;
  companySize: UserCompanySize;
  coldSource: UserSource;
  coPages: UserDocumentPages;
  coworkingPages: UserDocumentPages;
  cdn: UserCdn;
  createdAt: UserCreatedAt;
  dateOfBirth: UserDateOfBirth;
  debtLimit: UserDebtLimit;
  debt: UserDebt;
  clientDebt: UserClientDebt;
  email: UserEmail;
  facebook: UserFacebook;
  favorite: UserFavorite;
  fullName: UserFullName;
  groups: UserGroups;
  id: UserID;
  image: {
    file: UserImageFile;
  };
  integration: UserIntegration;
  isMentor: UserIsMentor;
  itnPages: UserDocumentPages;
  leadCreationDate: UserLeadCreationDate;
  linkedIn: UserLinkedIn;
  locale: UserLocale;
  location: UserLocation;
  manualClientStatus: UserManualClientStatus;
  marketingSource: UserSource;
  mentor: UserMentor;
  name: UserName;
  nameEn: UserNameEn;
  nameUk: UserNameUk;
  nanoId: UserNanoID;
  nda: UserNDA;
  ndaPages: UserDocumentPages;
  noGo: UserNoGo;
  paPages: UserDocumentPages;
  passportPages: UserDocumentPages;
  pePages: UserDocumentPages;
  phone: UserPhone;
  position: UserPosition;
  proficiencyLevel: UserProficiencyLevel;
  projectManager: UserProjectManager;
  providedFullName: UserProvidedFullName;
  providedFullNameEn: UserProvidedFullNameEn;
  providedFullNameUk: UserProvidedFullNameUk;
  qualifications: UserQualifications;
  rateEducationPoint: UserRateEducationPoint;
  rating: UserRating;
  ratio: UserRatio;
  roles: { id: UserRoleId }[];
  holdRate: UserHoldRate;
  realDeclineReasons: UserRealDeclineReasons;
  referer: UserReferer;
  schoolAgreementPages: UserDocumentPages;
  ratingCorrectedManually: UserRatingCorrectedManually;
  financeRoleName: UserFinanceRoleName;
  financeRole: {
    id: UserRoleId;
  };
  site: UserSite;
  socialNetworks: UserSocialNetworks;
  source: UserSource;
  specializations: UserSpecializations;
  subCategories: UserSubCategories;
  surname: UserSurname;
  surnameEn: UserSurnameEn;
  surnameUk: UserSurnameUk;
  salesManager: {
    id: UserID;
  };
  systemAccount: UserSystemAccount;
  testProject: {
    id: UserTestProjectID;
    uuid: UserTestProjectUUID;
    nanoId: UserTestProjectNanoID;
    createdAt: UserTestProjectCreatedAt;
    implementationDate: UserTestProjectImplementationDate;
    tasks: {
      startDate: UserTestProjectTaskStartDate;
    }[];
    members: {
      id: UserTestProjectMembersID;
      fullName: UserTestProjectMembersFullName;
      financeRoleName: UserTestProjectMembersFinanceRoleName;
    }[];
  };
  testTask: {
    id: UserTestTaskId;
    nanoId: UserTestTaskNanoId;
  };
  threeDServices: {
    id: UserTheeDServicesId;
  }[];
  currentTeam: {
    id: UserCurrentTeamID;
    name: UserCurrentTeamName;
    nanoId: UserCurrentTeamNanoID;
    type: UserCurrentTeamType;
  };
  userInTeams: {
    uuid: TeamUUID;
    name: TeamName;
    nanoId: TeamNanoID;
  }[];
  estimationAmount: UserEstimationAmount;
  estimationDate: UserEstimationDate;
  timeZone: UserTimeZone;
  totalAmount: UserTotalAmount;
  turnAroundTimeMonthly: UserTurnAroundTimeMonthly;
  updatedAt: UserUpdatedAt;
  uuid: UserUUID;
  urlId: UserUrlId;
  wildlyImportantGoalsCount: UserWildlyImportantGoalsCount;
  workerBalance: UserWorkerBalance;
  workerRateMedian: UserWorkerRateMedian;
  workerRateAverage: UserWorkerRateAverage;
  workExperience: UserWorkExperience;
}

export const FETCH_SHOW_USER_QUERY = gql`
  query ShowUser($uuid: ID!) {
    user(uuid: $uuid) {
      address
      additionalEmail1
      additionalEmail2
      additionalInformation
      agreedHourlyRate
      airTableApiKey
      airTableBaseId
      airTableEmail
      airTablePassword
      airTableTableName
      blocked
      businessEmail
      bonusAccount
      category {
        id
      }
      cells {
        id
      }
      city
      cdn
      client
      clientBalance
      clientStatus
      holdRate
      debtLimit
      clientManager {
        id
      }
      salesManager {
        id
      }
      company
      companySize {
        id
      }
      coldSource {
        id
      }
      coPages {
        ext
        file
        fileName
        id
        name
        nanoId
        size
        type
        uuid
      }
      coworkingPages {
        ext
        file
        fileName
        id
        name
        nanoId
        size
        type
        uuid
      }
      createdAt
      currency
      # dateOfBirth
      debt
      clientDebt
      clientRateAverage
      clientRateMedian
      deferredPayment
      discount
      email
      estimationAmount
      expectedPaymentDate
      expectedPaymentAmount
      facebook
      favorite
      financeRole {
        id
      }
      financeTags {
        id
      }
      firstPaymentDate
      fullName
      groups {
        id
      }
      id
      image {
        file
      }
      integration {
        id
      }
      isMentor
      itnPages {
        ext
        file
        fileName
        id
        name
        nanoId
        size
        type
        uuid
      }
      lastPaymentDate
      leadCreationDate
      linkedIn
      locale
      # location
      manualClientStatus
      marketingSource {
        id
      }
      mentor {
        id
      }
      name
      nameEn
      nameUk
      nanoId
      nda
      ndaPages {
        ext
        file
        fileName
        id
        name
        nanoId
        size
        type
        uuid
      }
      noGo
      paPages {
        ext
        file
        fileName
        id
        name
        nanoId
        size
        type
        uuid
      }
      passportPages {
        ext
        file
        fileName
        id
        name
        nanoId
        size
        type
        uuid
      }
      pePages {
        ext
        file
        fileName
        id
        name
        nanoId
        size
        type
        uuid
      }
      paymentTypes {
        id
      }
      payoneerId
      payoneerIdPe
      payoneerIdType
      phone
      position {
        id
      }
      proficiencyLevel {
        id
      }
      projectManager {
        id
        image {
          file
        }
      }
      providedFullName
      providedFullNameEn
      providedFullNameUk
      qualifications {
        id
      }
      rateEducationPoint
      rating
      ratio
      roles {
        id
      }
      realDeclineReasons {
        id
      }
      referer
      ratingCorrectedManually
      schoolAgreementPages {
        ext
        file
        fileName
        id
        name
        nanoId
        size
        type
        uuid
      }
      financeRoleName
      site
      socialNetworks
      source {
        id
      }
      specializations {
        id
      }
      subCategories {
        id
      }
      surname
      surnameEn
      surnameUk
      systemAccount
      testProject {
        id
        uuid
        nanoId
        createdAt
        implementationDate
        tasks {
          startDate
        }
        members {
          id
          fullName
          financeRoleName
        }
      }
      testTask {
        id
        nanoId
      }
      currentTeam {
        id
        name
        nanoId
        type
      }
      userInTeams {
        uuid
        name
        nanoId
      }
      threeDServices {
        id
      }
      estimationAmount
      estimationDate
      # timeZone
      totalAmount
      turnAroundTimeMonthly
      updatedAt
      uuid
      urlId
      wildlyImportantGoalsCount
      workerBalance
      workExperience
      workerRateAverage
      workerRateMedian
    }
  }
`;
